<template>
  <v-container fluid class="mt-5">
    <v-row justify="center">
      <v-col cols="11">
        dashboard
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
    }
  }
}
</script>
